import 'lib-flexible'
import Vue from 'vue'
import { Toast, Button, Icon, NavBar, Popup, Picker, RadioGroup, Radio, DatetimePicker, Dialog, Checkbox, CheckboxGroup } from 'vant'
import App from './App.vue'
import store from './store'
import '@/assets/scss/index.scss'
// import { isSit } from '@/utils/is'

Toast.allowMultiple(true)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatetimePicker)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

Vue.config.productionTip = false

new Vue({
    store,
    render: (h) => h(App)
}).$mount('#app')
