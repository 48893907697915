
















import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import queryString from 'query-string'
import StepHeader from './components/step-header/index.vue'
import EpidemicForm from './components/epidemic-form/index.vue'
import CompareFace from './components/compare-face/index.vue'
import Finish from './components/finish/index.vue'
import { isArray, isString } from '@/helper/is'
import { Tracker } from './helper/tracker'

const synth = window.speechSynthesis
const msg = new SpeechSynthesisUtterance()
const speckTextNew = (txt: string) => {
    msg.text = txt
    msg.lang = 'zh-CN' // 使用的语言:中文
    synth.speak(msg) // 播放
}

export default Vue.extend({
    data() {
        return {
            hotelCode: '',
            canIUseMedia: null as boolean | null,
            serverTime: Math.floor(Date.now() / 1000)
        }
    },
    computed: {
        ...mapGetters(['step'])
    },
    created() {
        const { hotelCode }: { hotelCode?: string | string[] } = queryString.parseUrl(window.location.href).query
        let realHotelCode = ''
        if (hotelCode) {
            if (isString(hotelCode)) {
                realHotelCode = hotelCode
            } else if (isArray(hotelCode) && hotelCode[0]) {
                realHotelCode = hotelCode[0]
            }
            this.hotelCode = realHotelCode
        }
        this.mediaTest()
        // speckTextNew('我的酒店')
        // setTimeout(() => {
        //     console.log('speckTextNewspeckTextNew');
        //     speckTextNew('我的酒店')
        // }, 5000)
        const audio: any = new Audio('https://static.sit.whalepms.com/2333.m4a')
        // audio.setAttribute('autoplay', 'muted')
        audio.play()
        setTimeout(() => {
            console.log('speckTextNewspeckTextNew')
            const audio: any = new Audio('https://static.sit.whalepms.com/2333.m4a')
            // audio.setAttribute('autoplay', 'muted')
            audio.play()
        }, 5000)
    },
    mounted() {
        // lottie.loadAnimation({
        //     container: this.$refs.lottieRef as any,
        //     renderer: 'svg',
        //     loop: true,
        //     autoplay: true,
        //     path: 'https://static.sit.whalepms.com/a.json'
        // });
        Tracker.init()
    },
    methods: {
        ...mapMutations(['updateData']),
        ...mapActions(['setStep']),
        mediaTest() {
            const canIUseMedia = Boolean(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) || Boolean((navigator as any).webkitGetUserMedia) || Boolean(navigator.getUserMedia)
            this.canIUseMedia = canIUseMedia
            return canIUseMedia
        },
        onClickLeft() {
            this.setStep(1)
        }
    },
    components: {
        StepHeader,
        EpidemicForm,
        CompareFace,
        Finish
    }
})
